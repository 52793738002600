import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classnames from 'classnames';
import styles from './styles.module.scss';

function HomeFooter({ theme }) {
  return (
    <div className={classnames(styles.HomeFooter, styles[`theme${theme}`])}>
      <Link className={styles.footer} to="/contact">
        Scribbledwall
      </Link>
    </div>
  );
}

HomeFooter.propTypes = {
  theme: PropTypes.oneOf(['SexyPackages', 'ChasingDreams', 'NewChallenger'])
    .isRequired,
};

export default HomeFooter;
