import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classnames from 'classnames';
import styles from './styles.module.scss';

function HomeNav({ theme }) {
  return (
    <div className={classnames(styles.homeNav, styles[`theme${theme}`])}>
      <Link className={styles.logo} to="/">
        Scribbledwall
      </Link>

      <ul className={styles.navList}>
        <li className={styles.navListItem}>
          <Link
            className={classnames(styles.navLink, styles.navLinkHome)}
            to="/"
          >
            Home
          </Link>
        </li>
        <li className={styles.navListItem}>
          <Link
            className={classnames(styles.navLink, styles.navLinkNews)}
            to="/blog"
          >
            News
          </Link>
        </li>
        <li className={styles.navListItem}>
          <a
            className={classnames(styles.navLink, styles.navLinkShop)}
            href="http://www.redbubble.com/people/scribbledwall/shop"
            target="_blank"
            rel="noopener noreferrer"
          >
            Shop
          </a>
        </li>
        <li className={styles.navListItem}>
          <Link
            className={classnames(styles.navLink, styles.navLinkAbout)}
            to="/about"
          >
            About
          </Link>
        </li>
        <li className={styles.navListItem}>
          <Link
            className={classnames(styles.navLink, styles.navLinkContact)}
            to="/contact"
          >
            Contact
          </Link>
        </li>
      </ul>
    </div>
  );
}

HomeNav.propTypes = {
  theme: PropTypes.oneOf(['SexyPackages', 'ChasingDreams', 'NewChallenger'])
    .isRequired,
};

export default HomeNav;
