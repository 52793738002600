import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classnames from 'classnames';
import styles from './styles.module.scss';

function HomeSplash({ theme }) {
  const altText = {
    SexyPackages: 'Great things come in sexy packages',
    ChasingDreams: 'Chasing dreams',
    NewChallenger: 'New Challenger - WTF Mommy?!?',
  };

  return (
    <Link
      className={classnames(styles.HomeSplash, styles[`theme${theme}`])}
      to="/blog"
    >
      <div className={styles.SplashImage}>{altText[theme]}</div>
    </Link>
  );
}

HomeSplash.propTypes = {
  theme: PropTypes.oneOf(['SexyPackages', 'ChasingDreams', 'NewChallenger'])
    .isRequired,
};

export default HomeSplash;
