import React from 'react';

import Page from '../components/Page';
import SEO from '../components/seo';
import HomeNav from '../components/Home/HomeNav';
import HomeSplash from '../components/Home/HomeSplash';
import HomeFooter from '../components/Home/HomeFooter';

function Index() {
  const themes = [
    {
      name: 'SexyPackages',
      bgColour: '#63b3a8',
    },
    {
      name: 'ChasingDreams',
      bgColour: '#2e814d',
    },
    {
      name: 'NewChallenger',
      bgColour: '#000',
    },
  ];
  const themeIndex = Math.floor(Math.random() * themes.length);
  const theme = themes[themeIndex];

  return (
    <Page bgColour={theme.bgColour}>
      <SEO keywords={[`gatsby`, `javascript`, `react`]} />

      <HomeNav theme={theme.name} />

      <HomeSplash theme={theme.name} />

      <HomeFooter theme={theme.name} />
    </Page>
  );
}

export default Index;
